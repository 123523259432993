import type { actions, domains } from '@/models/rbac'

export default defineNuxtRouteMiddleware((to) => {
  // skips middleware on server side -- only helps local dev
  if (process.server) return

  const { $log, $userCan, isHydrating, payload } = useNuxtApp()

  // skips middleware on first browser run (or refresh) -- allows client to run after
  if (process.client && isHydrating && payload.serverRendered) return

  let allowed = false

  if (to.meta.allowedAction && to.meta.allowedDomain) {
    if (
      $userCan(
        to.meta.allowedAction as actions,
        to.meta.allowedDomain as domains,
        parseInt(to.params.companyId as string),
      )
    ) {
      allowed = true
    }
  } else {
    $log.error('Permission requested but none assigned')
  }

  if (!allowed) {
    return abortNavigation(
      createError({
        statusCode: 401,
        message: 'You do not have permission to access this page.',
      }),
    )
  }
})
